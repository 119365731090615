@charset "UTF-8";

/* ********************************************************
	共通初期化CSS
******************************************************** */
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&subset=japanese");

@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700");

@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&subset=japanese");

@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700");

@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&subset=japanese");

@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700");

@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&subset=japanese");

@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700");

html,
body,
div,
header,
footer,
article,
section,
aside,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
figure {
	margin: 0px;
	padding: 0px;
	border: 0px;
	outline: none;
}

body {
	-webkit-text-size-adjust: 100%;
	line-height: 100%;
}

ol,
ul,
li {
	list-style: none;
}

img {
	-webkit-backface-visibility: hidden;
	-ms-interpolation-mode: bicubic;
	vertical-align: bottom;
}

ins {
	text-decoration: none;
}

del {
	text-decoration: line-through;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

:focus {
	outline: 0;
}

caption,
th,
td {
	text-align: left;
	font-weight: normal;
}

.clear,
div .clear {
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	text-indent: -9999px;
	clear: both;
}

/*---clearFix---*/

.clearFix {
	zoom: 1;

	&:after {
		content: ".";
		clear: both;
		display: block;
		height: 0;
		visibility: hidden;
	}
}

@media print {
	body {
		zoom: 60%;
	}
}

/*@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icon_otherwindow: "\e900"; //別窓表示
$icon_arrow_bottom: "\e901"; //下向き矢印
$icon_arrow_left: "\e902"; //左向き矢印
$icon_arrow_right: "\e903"; //右向き矢印
$icon_arrow_top: "\e904"; //上向き矢印
$icon_plus: "\e906"; //プラスアイコン
$icon_minus: "\e905"; //マイナスアイコン
$icon_twitter: "\e909"; //ツイッターアイコン
$icon_fb: "\e907"; //フェイスブックアイコン
$icon_instagram: "\e908"; //インスタグラムアイコン*/
/*@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icon_otherwindow: "\e900"; //別窓表示
$icon_arrow_bottom: "\e901"; //下向き矢印
$icon_arrow_left: "\e902"; //左向き矢印
$icon_arrow_right: "\e903"; //右向き矢印
$icon_arrow_top: "\e904"; //上向き矢印
$icon_plus: "\e906"; //プラスアイコン
$icon_minus: "\e905"; //マイナスアイコン
$icon_twitter: "\e909"; //ツイッターアイコン
$icon_fb: "\e907"; //フェイスブックアイコン
$icon_instagram: "\e908"; //インスタグラムアイコン*/
/*@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icon_otherwindow: "\e900"; //別窓表示
$icon_arrow_bottom: "\e901"; //下向き矢印
$icon_arrow_left: "\e902"; //左向き矢印
$icon_arrow_right: "\e903"; //右向き矢印
$icon_arrow_top: "\e904"; //上向き矢印
$icon_plus: "\e906"; //プラスアイコン
$icon_minus: "\e905"; //マイナスアイコン
$icon_twitter: "\e909"; //ツイッターアイコン
$icon_fb: "\e907"; //フェイスブックアイコン
$icon_instagram: "\e908"; //インスタグラムアイコン*/
/*%iconfont {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: top;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}*/
/*@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icon_otherwindow: "\e900"; //別窓表示
$icon_arrow_bottom: "\e901"; //下向き矢印
$icon_arrow_left: "\e902"; //左向き矢印
$icon_arrow_right: "\e903"; //右向き矢印
$icon_arrow_top: "\e904"; //上向き矢印
$icon_plus: "\e906"; //プラスアイコン
$icon_minus: "\e905"; //マイナスアイコン
$icon_twitter: "\e909"; //ツイッターアイコン
$icon_fb: "\e907"; //フェイスブックアイコン
$icon_instagram: "\e908"; //インスタグラムアイコン*/
/* ********************************************************
	add style CSS
******************************************************** */

html {
	overflow: auto;
	height: 100%;
	font-size: 62.5%;
	ine-height: 1.6;
}

body {
	height: 100%;
	font-size: 62.5%;
	ine-height: 1.6;
	-webkit-text-size-adjust: 100%;
	font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "メイリオ",
		sans-serif;
	line-height: 1.6;
	color: #000;
}

a[href^="tel:"] {
	text-decoration: none;

	&:hover {
		cursor: default;
	}
}

#container {
	width: 100%;
	width: 100%;
	overflow: hidden;
	padding-top: 160px;

	&.modal {
		padding: 0;
	}

	#contents {
		width: 100%;
	}
}

@media screen and (max-width: 769px) {
	.pc {
		display: none !important;
	}
}

@media screen and (min-width: 769px) {
	.sp {
		display: none !important;
	}
}

@media screen and (max-width: 980px) {
	#container {
		padding-top: 80px;
	}
}

a {
	text-decoration: none;
}

/*-------------------------------
  Header
-------------------------------*/

#pageHeader {
	background: #fff;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
	z-index: 100;

	#headerInner {
		height: 160px;
		position: relative;

		.logo {
			width: 352px;
			position: absolute;
			top: 55px;
			left: 0;
			right: 0;
			margin: auto;

			a {
				overflow: hidden;
				text-indent: 100%;
				white-space: nowrap;
				background: url("../images/common/img_logo.svg") center center no-repeat;
				background-size: contain;
				width: 100%;
				height: 60px;
				display: block;
			}
		}
	}
}

@media screen and (max-width: 980px) {
	#pageHeader #headerInner {
		height: 80px;
	}
}

@media screen and (max-width: 980px) {
	#pageHeader #headerInner .logo {
		width: 60%;
		top: 50%;
		margin-top: -30px;
	}
}

/*-------------------------------
  navGlobal
-------------------------------*/
@media screen and (max-width: 980px) {
	#navGlobal {
		width: 100%;
		position: absolute;
		top: 80px;
		left: 0;
	}
}

#navGlobal {
	#navOpen {
		display: none;

		span {
			overflow: hidden;
			text-indent: 100%;
			white-space: nowrap;
			width: 15px;
			height: 1px;
			display: block;
			background: #bf000a;
			position: absolute;
			top: 50%;
			bottom: 0;
			right: 15px;
			transition: all 0.3s ease;
		}

		&:before,
		&:after {
			display: block;
			content: "";
			position: absolute;
			width: 11px;
			height: 1px;
			background: #000;
		}

		&:before {
			width: 20px;
			top: 15px;
			right: 15px;
		}

		&:after {
			width: 5px;
			bottom: 15px;
			right: 15px;
		}

		&.open {
			span {
				opacity: 0;
			}

			&:before {
				width: 20px;
				top: 24px;
				transform: rotateZ(45deg);
			}

			&:after {
				width: 20px;
				bottom: 25px;
				transform: rotateZ(-45deg);
			}
		}
	}

	.change_lang {
		display: flex;
		position: absolute;
		right: 10px;
		bottom: 8px;
		z-index: 100;

		a {
			font-size: 12px;
			font-size: 1.4rem;
			color: #000;
			padding: 0 10px;
			font-weight: bold;
			transition: all 0.3s ease;
		}

		li + li a {
			border-left: 1px solid #000;
		}
	}

	.navGroup__btnNav {
		display: flex;
		position: absolute;
		top: 0;
		right: 50%;
		margin-right: -560px;
		z-index: 1;

		li {
			width: 120px;
			position: relative;

			a {
				height: 125px;
				padding-top: 90px;
				box-sizing: border-box;
				display: block;
				color: #fff;
				text-align: center;
				position: relative;
				font-size: 16px;
				font-size: 1.6rem;
				transition: all 0.3s ease;

				&:before {
					content: "";
					background-position: center center;
					background-repeat: no-repeat;
					position: absolute;
					top: 30px;
					left: 0;
					right: 0;
					margin: auto;
				}

				&.register {
					background: #bf000a;

					&:before {
						background-image: url("../images/index/icon_shinki.svg");
						width: 34px;
						height: 36px;
					}

					&:hover {
						background: #cc333b;
					}
				}

				&.login {
					background: #000;

					&:before {
						background-image: url("../images/index/icon_login.svg");
						width: 32px;
						height: 38px;
					}

					&:hover {
						background: #333;
					}
				}

				&.mypage {
					background: #bf000a;

					&:before {
						background-image: url("../images/index/icon_mypage.svg");
						width: 40px;
						height: 40px;
					}

					&:hover {
						background: #cc333b;
					}

					span {
						margin-top: -15px;
						font-size: 11px;
						font-size: 1.1rem;
						display: block;

						span {
							margin: 0;
							font-size: 14px;
							font-size: 1.4rem;
							display: inline;
						}
					}
				}

				&.logout {
					background: #000;

					&:before {
						background-image: url("../images/index/icon_logout.svg");
						width: 40px;
						height: 40px;
					}

					&:hover {
						background: #333;
					}
				}
			}
		}
	}

	.navGroup__sabNav {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 35px;
		border-bottom: 1px solid #d3d3d3;
		position: absolute;
		top: 10px;
		left: 0;
		right: 0;
		margin: auto;

		li {
			a {
				font-size: 12px;
				font-size: 1.2rem;
				color: #000;
				padding: 0 10px;
				transition: all 0.3s ease;

				&:hover {
					opacity: 0.8;
				}
			}

			+ li a {
				border-left: 1px solid #000;
			}
		}
	}

	.navGroup__mainNav {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #efefef;
		width: 100%;
		height: 35px;
		border-bottom: 1px solid #d3d3d3;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;

		li {
			position: relative;

			a,
			span {
				font-size: 16px;
				font-size: 1.6rem;
				color: #000;
				padding: 0 30px;
				display: block;
				transition: all 0.3s ease;
			}

			a:hover {
				opacity: 0.8;
			}

			span {
				&:hover {
					opacity: 0.8;
				}

				cursor: pointer;
			}

			+ li {
				a,
				span {
					border-left: 1px solid #000;
				}
			}

			ul {
				position: absolute;
				top: 30px;
				left: 50%;
				margin-left: -65px;
				padding: 0;
				z-index: 10000;

				li {
					overflow: hidden;
					width: 130px;
					height: 0;
					transition: 0.2s;

					/*&:last-child{
                      a{
                        line-height: 1.5;
                        padding: 10px 0;
                        @include max-screen($PC01blake) {
                          padding: 0;
                          line-height: 60px;
                        }
                      }
                    }*/

					a {
						width: 100%;
						height: 60px;
						background: #000;
						text-align: center;
						border-left: none;
						font-size: 12px;
						font-size: 1.2rem;
						color: #fff;
						line-height: 60px;
						display: block;
						padding: 0 10px;
						box-sizing: border-box;

						&:hover {
							background: #333;
							opacity: 1;
						}
					}

					+ li a {
						border-top: 1px solid #fff;
					}
				}
			}

			&:hover ul li {
				height: 60px;
			}
		}
	}

	.navGroup__sns {
		display: none;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal #navOpen {
		appearance: none;
		width: 50px;
		height: 50px;
		display: block;
		background: #fff;
		position: fixed;
		top: 15px;
		right: 0;
		z-index: 100;
		border: none;
		cursor: pointer;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		text-indent: 500%;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .change_lang {
		position: static;
		justify-content: center;
		margin: 30px auto;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .change_lang a {
		font-size: 1.6rem;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup {
		background: #fff;
		display: none;
		width: 100%;
		height: calc(90vh - 80px);
		overflow: auto;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__btnNav {
		position: static;
		flex-direction: column;
		width: 90%;
		margin: 20px auto 0;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__btnNav li {
		width: 100%;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__btnNav li a {
		height: 63px;
		line-height: 63px;
		padding: 0;
		border-radius: 8px;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__btnNav li a:before {
		top: 50%;
		left: 20px;
		right: auto;
		margin: 0;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__btnNav li a.register:before {
		margin-top: -18px;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__btnNav li a.login {
		margin-top: 20px;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__btnNav li a.login:before {
		margin-top: -19px;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__btnNav li a.mypage:before {
		margin-top: -18px;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__btnNav li a.logout {
		margin-top: 20px;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__btnNav li a.logout:before {
		margin-top: -19px;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__sabNav {
		position: static;
		flex-direction: column;
		height: auto;
		border-bottom: none;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__sabNav li {
		width: 100%;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__sabNav li a {
		height: 65px;
		text-align: center;
		display: block;
		padding: 0;
		line-height: 65px;
		position: relative;
		font-size: 16px;
		font-size: 1.6rem;
		border-top: 1px solid #000;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__sabNav li a:after {
		content: "";
		width: 10px;
		height: 10px;
		position: absolute;
		top: 50%;
		right: 30px;
		margin-top: -5px;
		border-style: solid;
		border-width: 1px 1px 0 0;
		border-color: #fff;
		transform: rotateZ(45deg);
		border-color: #bf000a;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__sabNav li + li a {
		border-left: none;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__mainNav {
		background: none;
		margin-top: 20px;
		position: static;
		flex-direction: column;
		height: auto;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__mainNav li {
		width: 100%;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__mainNav li {
		a,
		span {
			height: 65px;
			text-align: center;
			display: block;
			padding: 0;
			line-height: 65px;
			position: relative;
		}
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__mainNav li {
		a:after,
		span:after {
			content: "";
			width: 10px;
			height: 10px;
			position: absolute;
			top: 50%;
			right: 30px;
			margin-top: -5px;
			border-style: solid;
			border-width: 1px 1px 0 0;
			border-color: #fff;
			transform: rotateZ(45deg);
			border-color: #bf000a;
		}
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__mainNav li span:after {
		content: "";
		width: 10px;
		height: 10px;
		position: absolute;
		top: 50%;
		right: 30px;
		margin-top: -5px;
		border-style: solid;
		border-width: 1px 1px 0 0;
		border-color: #fff;
		transform: rotateZ(45deg);
		border-color: #bf000a;
		transform: rotateZ(135deg);
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__mainNav li + li {
		a,
		span {
			border-left: none;
			border-top: 1px solid #000;
		}
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__mainNav li ul {
		display: none;
		position: static;
		margin: 0;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__mainNav li ul li {
		width: 100%;
		height: 60px;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__mainNav li ul li a {
		font-size: 14px;
		font-size: 1.4rem;
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__mainNav li.open span:after {
		content: "";
		width: 10px;
		height: 10px;
		position: absolute;
		top: 50%;
		right: 30px;
		margin-top: -5px;
		border-style: solid;
		border-width: 1px 1px 0 0;
		border-color: #fff;
		transform: rotateZ(45deg);
		border-color: #bf000a;
		transform: rotateZ(-45deg);
	}
}

@media screen and (max-width: 980px) {
	#navGlobal .navGroup__sns {
		display: block;
		background: #000;
		display: flex;
		align-items: center;
		justify-content: center;

		li {
			width: 100%;

			a {
				overflow: hidden;
				text-indent: 100%;
				white-space: nowrap;
				display: block;
				height: 65px;

				&.fb {
					background: url("../images/common/icon_fb.svg") center center
						no-repeat;
					width: 100%;
					height: 65px;
				}
			}
		}
	}
}

/*-------------------------------
  footer
-------------------------------*/

#pageFooter {
	.footerInner {
		position: relative;
	}

	.footerInner__nav ul {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 95px;
		border-bottom: 1px solid #d3d3d3;
		box-sizing: border-box;

		li {
			a {
				font-family: "Noto Serif JP", serif;
				font-size: 16px;
				font-size: 1.6rem;
				color: #000;
				padding: 0 20px;
				transition: all 0.3s ease;

				&:hover {
					opacity: 0.8;
				}
			}

			+ li a {
				border-left: 1px solid #000;
			}
		}
	}

	.footerInner__add {
		padding: 30px 0 40px;

		p {
			text-align: center;
			font-size: 14px;
			font-size: 1.4rem;
			color: #000;

			+ p {
				margin-top: 1em;
			}
		}
	}

	.footerInner .pageTop {
		position: absolute;
		top: -35px;
		right: 50%;
		margin-right: -512px;

		a {
			overflow: hidden;
			text-indent: 100%;
			white-space: nowrap;
			background: #bf000a;
			width: 70px;
			height: 70px;
			position: relative;
			border-radius: 50px;
			display: block;
			box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
			transition: all 0.3s ease;

			&:before {
				content: "";
				width: 10px;
				height: 10px;
				position: absolute;
				top: 50%;
				right: 30px;
				margin-top: -5px;
				border-style: solid;
				border-width: 1px 1px 0 0;
				border-color: #fff;
				transform: rotateZ(45deg);
				transform: rotateZ(-45deg);
			}

			&:hover {
				background: #cc333b;
			}
		}
	}

	#copyright {
		background: #000;
		width: 100%;
		height: 40px;
		display: block;
		color: #fff;
		font-size: 14px;
		font-size: 1.4rem;
		line-height: 40px;
		text-align: center;
	}
}

@media screen and (max-width: 769px) {
	#pageFooter .footerInner {
		width: 90%;
		margin: 0 auto;
	}
}

@media screen and (max-width: 980px) {
	#pageFooter .footerInner__nav ul {
		padding: 40px 0 20px;
	}
}

@media screen and (max-width: 769px) {
	#pageFooter .footerInner__nav ul {
		flex-direction: column;
		height: auto;
	}
}

@media screen and (max-width: 980px) {
	#pageFooter .footerInner__nav ul li a {
		padding: 0 15px;
		font-size: 14px;
		font-size: 1.4rem;
	}
}

@media screen and (max-width: 769px) {
	#pageFooter .footerInner__nav ul li a {
		height: 60px;
		line-height: 60px;
	}
}

@media screen and (max-width: 769px) {
	#pageFooter .footerInner__nav ul li + li a {
		border: none;
	}
}

@media screen and (max-width: 769px) {
	#pageFooter .footerInner__add p {
		text-align: left;
	}
}

@media screen and (max-width: 980px) {
	#pageFooter .footerInner .pageTop {
		right: 20px;
		margin-right: 0;
	}
}

@media screen and (max-width: 980px) {
	#pageFooter #copyright {
		font-size: 10px;
		font-size: 1rem;
	}
}

#english {
	#navGlobal .navGroup__btnNav li a.register {
		line-height: 1.1;
		font-size: 1.4rem;
		@media screen and (max-width: 769px) {
			font-size: 1.6rem;
			line-height: 60px;
		}
	}
	@media screen and (max-width: 769px) {
		#navGlobal .navGroup__mainNav li a,
		#navGlobal .navGroup__mainNav li span {
			line-height: 1.5;
			font-size: 1.4rem;
			height: auto;
			padding: 20px 0;
		}
		#navGlobal .navGroup__mainNav li a:after,
		#navGlobal .navGroup__mainNav li span:after {
			right: 20px;
		}
		#navGlobal .navGroup__sabNav li a {
			height: auto;
			padding: 20px 0;
			line-height: 1.5;
			font-size: 1.5rem;
		}
		#navGlobal .navGroup__sabNav li a:after {
			right: 20px;
		}
	}
	#footerInner {
		.footerInner__nav {
			ul li a {
				font-size: 1.3rem;
			}
			ul li + li a {
				font-size: 1.3rem;
				@media screen and (max-width: 769px) {
					font-size: 1rem;
					line-height: 1.6;
					height: 25px;
					text-align: center;
					padding: 0px 0;
					display: block;
				}
			}
		}
		@media screen and (max-width: 769px) {
			.footerInner__add p {
				font-size: 1.1rem;
			}
		}
	}
}
